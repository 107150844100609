/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  }
  
  .btn-default:active, .btn-primary:active, .btn-success:active, .btn-info:active, .btn-warning:active, .btn-danger:active, .btn-default.active, .btn-primary.active, .btn-success.active, .btn-info.active, .btn-warning.active, .btn-danger.active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  
  .btn-default.disabled, .btn-primary.disabled, .btn-success.disabled, .btn-info.disabled, .btn-warning.disabled, .btn-danger.disabled, .btn-default[disabled], .btn-primary[disabled], .btn-success[disabled], .btn-info[disabled], .btn-warning[disabled], .btn-danger[disabled] {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  fieldset[disabled] {
    .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  
  .btn-default .badge, .btn-primary .badge, .btn-success .badge, .btn-info .badge, .btn-warning .badge, .btn-danger .badge {
    text-shadow: none;
  }
  
  .btn {
    &:active, &.active {
      background-image: none;
    }
  }
  
  .btn-default {
    text-shadow: 0 1px 0 #fff;
    background-image: -webkit-linear-gradient(top, #fff 0%, #e0e0e0 100%);
    background-image: -o-linear-gradient(top, #fff 0%, #e0e0e0 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e0e0e0));
    background-image: linear-gradient(to bottom, #fff 0%, #e0e0e0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: #dbdbdb;
    border-color: #ccc;
  
    &:hover, &:focus {
      background-color: #e0e0e0;
      background-position: 0 -15px;
    }
  
    &:active, &.active {
      background-color: #e0e0e0;
      border-color: #dbdbdb;
    }
  
    &.disabled, &[disabled] {
      background-color: #e0e0e0;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-default {
    background-color: #e0e0e0;
    background-image: none;
  }
  
  .btn-default {
    &.disabled:hover, &[disabled]:hover {
      background-color: #e0e0e0;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-default:hover {
    background-color: #e0e0e0;
    background-image: none;
  }
  
  .btn-default {
    &.disabled:focus, &[disabled]:focus {
      background-color: #e0e0e0;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-default:focus {
    background-color: #e0e0e0;
    background-image: none;
  }
  
  .btn-default {
    &.disabled.focus, &[disabled].focus {
      background-color: #e0e0e0;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-default.focus {
    background-color: #e0e0e0;
    background-image: none;
  }
  
  .btn-default {
    &.disabled:active, &[disabled]:active {
      background-color: #e0e0e0;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-default:active {
    background-color: #e0e0e0;
    background-image: none;
  }
  
  .btn-default {
    &.disabled.active, &[disabled].active {
      background-color: #e0e0e0;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-default.active {
    background-color: #e0e0e0;
    background-image: none;
  }
  
  .btn-primary {
    background-image: -webkit-linear-gradient(top, #337ab7 0%, #265a88 100%);
    background-image: -o-linear-gradient(top, #337ab7 0%, #265a88 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#265a88));
    background-image: linear-gradient(to bottom, #337ab7 0%, #265a88 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff265a88', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: #245580;
  
    &:hover, &:focus {
      background-color: #265a88;
      background-position: 0 -15px;
    }
  
    &:active, &.active {
      background-color: #265a88;
      border-color: #245580;
    }
  
    &.disabled, &[disabled] {
      background-color: #265a88;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-primary {
    background-color: #265a88;
    background-image: none;
  }
  
  .btn-primary {
    &.disabled:hover, &[disabled]:hover {
      background-color: #265a88;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-primary:hover {
    background-color: #265a88;
    background-image: none;
  }
  
  .btn-primary {
    &.disabled:focus, &[disabled]:focus {
      background-color: #265a88;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-primary:focus {
    background-color: #265a88;
    background-image: none;
  }
  
  .btn-primary {
    &.disabled.focus, &[disabled].focus {
      background-color: #265a88;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-primary.focus {
    background-color: #265a88;
    background-image: none;
  }
  
  .btn-primary {
    &.disabled:active, &[disabled]:active {
      background-color: #265a88;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-primary:active {
    background-color: #265a88;
    background-image: none;
  }
  
  .btn-primary {
    &.disabled.active, &[disabled].active {
      background-color: #265a88;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-primary.active {
    background-color: #265a88;
    background-image: none;
  }
  
  .btn-success {
    background-image: -webkit-linear-gradient(top, #5cb85c 0%, #419641 100%);
    background-image: -o-linear-gradient(top, #5cb85c 0%, #419641 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5cb85c), to(#419641));
    background-image: linear-gradient(to bottom, #5cb85c 0%, #419641 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: #3e8f3e;
  
    &:hover, &:focus {
      background-color: #419641;
      background-position: 0 -15px;
    }
  
    &:active, &.active {
      background-color: #419641;
      border-color: #3e8f3e;
    }
  
    &.disabled, &[disabled] {
      background-color: #419641;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-success {
    background-color: #419641;
    background-image: none;
  }
  
  .btn-success {
    &.disabled:hover, &[disabled]:hover {
      background-color: #419641;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-success:hover {
    background-color: #419641;
    background-image: none;
  }
  
  .btn-success {
    &.disabled:focus, &[disabled]:focus {
      background-color: #419641;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-success:focus {
    background-color: #419641;
    background-image: none;
  }
  
  .btn-success {
    &.disabled.focus, &[disabled].focus {
      background-color: #419641;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-success.focus {
    background-color: #419641;
    background-image: none;
  }
  
  .btn-success {
    &.disabled:active, &[disabled]:active {
      background-color: #419641;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-success:active {
    background-color: #419641;
    background-image: none;
  }
  
  .btn-success {
    &.disabled.active, &[disabled].active {
      background-color: #419641;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-success.active {
    background-color: #419641;
    background-image: none;
  }
  
  .btn-info {
    background-image: -webkit-linear-gradient(top, #5bc0de 0%, #2aabd2 100%);
    background-image: -o-linear-gradient(top, #5bc0de 0%, #2aabd2 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5bc0de), to(#2aabd2));
    background-image: linear-gradient(to bottom, #5bc0de 0%, #2aabd2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2aabd2', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: #28a4c9;
  }
  
  a.btn-info {
    color: #fff;
  
    &:link {
      color: #fff;
    }
  }
  
  .btn-info {
    &:hover, &:focus {
      background-color: #2aabd2;
      background-position: 0 -15px;
    }
  
    &:active, &.active {
      background-color: #2aabd2;
      border-color: #28a4c9;
    }
  
    &.disabled, &[disabled] {
      background-color: #2aabd2;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-info {
    background-color: #2aabd2;
    background-image: none;
  }
  
  .btn-info {
    &.disabled:hover, &[disabled]:hover {
      background-color: #2aabd2;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-info:hover {
    background-color: #2aabd2;
    background-image: none;
  }
  
  .btn-info {
    &.disabled:focus, &[disabled]:focus {
      background-color: #2aabd2;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-info:focus {
    background-color: #2aabd2;
    background-image: none;
  }
  
  .btn-info {
    &.disabled.focus, &[disabled].focus {
      background-color: #2aabd2;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-info.focus {
    background-color: #2aabd2;
    background-image: none;
  }
  
  .btn-info {
    &.disabled:active, &[disabled]:active {
      background-color: #2aabd2;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-info:active {
    background-color: #2aabd2;
    background-image: none;
  }
  
  .btn-info {
    &.disabled.active, &[disabled].active {
      background-color: #2aabd2;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-info.active {
    background-color: #2aabd2;
    background-image: none;
  }
  
  .btn-warning {
    background-image: -webkit-linear-gradient(top, #f0ad4e 0%, #eb9316 100%);
    background-image: -o-linear-gradient(top, #f0ad4e 0%, #eb9316 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f0ad4e), to(#eb9316));
    background-image: linear-gradient(to bottom, #f0ad4e 0%, #eb9316 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffeb9316', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: #e38d13;
  
    &:hover, &:focus {
      background-color: #eb9316;
      background-position: 0 -15px;
    }
  
    &:active, &.active {
      background-color: #eb9316;
      border-color: #e38d13;
    }
  
    &.disabled, &[disabled] {
      background-color: #eb9316;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-warning {
    background-color: #eb9316;
    background-image: none;
  }
  
  .btn-warning {
    &.disabled:hover, &[disabled]:hover {
      background-color: #eb9316;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-warning:hover {
    background-color: #eb9316;
    background-image: none;
  }
  
  .btn-warning {
    &.disabled:focus, &[disabled]:focus {
      background-color: #eb9316;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-warning:focus {
    background-color: #eb9316;
    background-image: none;
  }
  
  .btn-warning {
    &.disabled.focus, &[disabled].focus {
      background-color: #eb9316;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-warning.focus {
    background-color: #eb9316;
    background-image: none;
  }
  
  .btn-warning {
    &.disabled:active, &[disabled]:active {
      background-color: #eb9316;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-warning:active {
    background-color: #eb9316;
    background-image: none;
  }
  
  .btn-warning {
    &.disabled.active, &[disabled].active {
      background-color: #eb9316;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-warning.active {
    background-color: #eb9316;
    background-image: none;
  }
  
  .btn-danger {
    background-image: -webkit-linear-gradient(top, #d9534f 0%, #c12e2a 100%);
    background-image: -o-linear-gradient(top, #d9534f 0%, #c12e2a 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d9534f), to(#c12e2a));
    background-image: linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc12e2a', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-color: #b92c28;
  
    &:hover, &:focus {
      background-color: #c12e2a;
      background-position: 0 -15px;
    }
  
    &:active, &.active {
      background-color: #c12e2a;
      border-color: #b92c28;
    }
  
    &.disabled, &[disabled] {
      background-color: #c12e2a;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-danger {
    background-color: #c12e2a;
    background-image: none;
  }
  
  .btn-danger {
    &.disabled:hover, &[disabled]:hover {
      background-color: #c12e2a;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-danger:hover {
    background-color: #c12e2a;
    background-image: none;
  }
  
  .btn-danger {
    &.disabled:focus, &[disabled]:focus {
      background-color: #c12e2a;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-danger:focus {
    background-color: #c12e2a;
    background-image: none;
  }
  
  .btn-danger {
    &.disabled.focus, &[disabled].focus {
      background-color: #c12e2a;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-danger.focus {
    background-color: #c12e2a;
    background-image: none;
  }
  
  .btn-danger {
    &.disabled:active, &[disabled]:active {
      background-color: #c12e2a;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-danger:active {
    background-color: #c12e2a;
    background-image: none;
  }
  
  .btn-danger {
    &.disabled.active, &[disabled].active {
      background-color: #c12e2a;
      background-image: none;
    }
  }
  
  fieldset[disabled] .btn-danger.active {
    background-color: #c12e2a;
    background-image: none;
  }
  
  .thumbnail, .img-thumbnail {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-menu > {
    li > a {
      &:hover, &:focus {
        background-color: #e8e8e8;
        background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
        background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#e8e8e8));
        background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
        background-repeat: repeat-x;
      }
    }
  
    .active > a {
      background-color: #2e6da4;
      background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
      background-image: -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
      background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
      background-repeat: repeat-x;
  
      &:hover, &:focus {
        background-color: #2e6da4;
        background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
        background-image: -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
        background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
        background-repeat: repeat-x;
      }
    }
  }
  
  .navbar-default {
    background-image: -webkit-linear-gradient(top, #fff 0%, #f8f8f8 100%);
    background-image: -o-linear-gradient(top, #fff 0%, #f8f8f8 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f8f8f8));
    background-image: linear-gradient(to bottom, #fff 0%, #f8f8f8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
  
    .navbar-nav > {
      .open > a, .active > a {
        background-image: -webkit-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
        background-image: -o-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), to(#e2e2e2));
        background-image: linear-gradient(to bottom, #dbdbdb 0%, #e2e2e2 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdbdbdb', endColorstr='#ffe2e2e2', GradientType=0);
        background-repeat: repeat-x;
        -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
      }
    }
  }
  
  .navbar-brand, .navbar-nav > li > a {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
  }
  
  .navbar-inverse {
    background-image: -webkit-linear-gradient(top, #3c3c3c 0%, #222 100%);
    background-image: -o-linear-gradient(top, #3c3c3c 0%, #222 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#3c3c3c), to(#222));
    background-image: linear-gradient(to bottom, #3c3c3c 0%, #222 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
    border-radius: 4px;
  
    .navbar-nav > {
      .open > a, .active > a {
        background-image: -webkit-linear-gradient(top, #080808 0%, #0f0f0f 100%);
        background-image: -o-linear-gradient(top, #080808 0%, #0f0f0f 100%);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#080808), to(#0f0f0f));
        background-image: linear-gradient(to bottom, #080808 0%, #0f0f0f 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff080808', endColorstr='#ff0f0f0f', GradientType=0);
        background-repeat: repeat-x;
        -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
        box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
      }
    }
  
    .navbar-brand, .navbar-nav > li > a {
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
  }
  
  .navbar-static-top, .navbar-fixed-top, .navbar-fixed-bottom {
    border-radius: 0;
  }
  
  @media (max-width: 767px) {
    .navbar .navbar-nav .open .dropdown-menu > .active > a {
      color: #fff;
      background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
      background-image: -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
      background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
      background-repeat: repeat-x;
  
      &:hover, &:focus {
        color: #fff;
        background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
        background-image: -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
        background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
        background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
        background-repeat: repeat-x;
      }
    }
  }
  
  .alert {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .alert-success {
    background-image: -webkit-linear-gradient(top, #dff0d8 0%, #c8e5bc 100%);
    background-image: -o-linear-gradient(top, #dff0d8 0%, #c8e5bc 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#dff0d8), to(#c8e5bc));
    background-image: linear-gradient(to bottom, #dff0d8 0%, #c8e5bc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffc8e5bc', GradientType=0);
    background-repeat: repeat-x;
    border-color: #b2dba1;
  }
  
  .alert-info {
    background-image: -webkit-linear-gradient(top, #d9edf7 0%, #b9def0 100%);
    background-image: -o-linear-gradient(top, #d9edf7 0%, #b9def0 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d9edf7), to(#b9def0));
    background-image: linear-gradient(to bottom, #d9edf7 0%, #b9def0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
    background-repeat: repeat-x;
    border-color: #9acfea;
  }
  
  .alert-warning {
    background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #f8efc0 100%);
    background-image: -o-linear-gradient(top, #fcf8e3 0%, #f8efc0 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fcf8e3), to(#f8efc0));
    background-image: linear-gradient(to bottom, #fcf8e3 0%, #f8efc0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fff8efc0', GradientType=0);
    background-repeat: repeat-x;
    border-color: #f5e79e;
  }
  
  .alert-danger {
    background-image: -webkit-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
    background-image: -o-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f2dede), to(#e7c3c3));
    background-image: linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
    background-repeat: repeat-x;
    border-color: #dca7a7;
  }
  
  .progress {
    background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
    background-image: -o-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ebebeb), to(#f5f5f5));
    background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .progress-bar {
    background-image: -webkit-linear-gradient(top, #337ab7 0%, #286090 100%);
    background-image: -o-linear-gradient(top, #337ab7 0%, #286090 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#286090));
    background-image: linear-gradient(to bottom, #337ab7 0%, #286090 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff286090', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .progress-bar-success {
    background-image: -webkit-linear-gradient(top, #5cb85c 0%, #449d44 100%);
    background-image: -o-linear-gradient(top, #5cb85c 0%, #449d44 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5cb85c), to(#449d44));
    background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .progress-bar-info {
    background-image: -webkit-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
    background-image: -o-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5bc0de), to(#31b0d5));
    background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .progress-bar-warning {
    background-image: -webkit-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
    background-image: -o-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f0ad4e), to(#ec971f));
    background-image: linear-gradient(to bottom, #f0ad4e 0%, #ec971f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffec971f', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .progress-bar-danger {
    background-image: -webkit-linear-gradient(top, #d9534f 0%, #c9302c 100%);
    background-image: -o-linear-gradient(top, #d9534f 0%, #c9302c 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d9534f), to(#c9302c));
    background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  }
  
  .list-group {
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  }
  
  .list-group-item.active {
    text-shadow: 0 -1px 0 #286090;
    background-image: -webkit-linear-gradient(top, #337ab7 0%, #2b669a 100%);
    background-image: -o-linear-gradient(top, #337ab7 0%, #2b669a 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2b669a));
    background-image: linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2b669a', GradientType=0);
    background-repeat: repeat-x;
    border-color: #2b669a;
  
    &:hover, &:focus {
      text-shadow: 0 -1px 0 #286090;
      background-image: -webkit-linear-gradient(top, #337ab7 0%, #2b669a 100%);
      background-image: -o-linear-gradient(top, #337ab7 0%, #2b669a 100%);
      background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2b669a));
      background-image: linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2b669a', GradientType=0);
      background-repeat: repeat-x;
      border-color: #2b669a;
    }
  
    .badge, &:hover .badge, &:focus .badge {
      text-shadow: none;
    }
  }
  
  .panel {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .panel-default > .panel-heading {
    background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
    background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#e8e8e8));
    background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .panel-primary > .panel-heading {
    background-image: -webkit-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
    background-image: -o-linear-gradient(top, #337ab7 0%, #2e6da4 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337ab7), to(#2e6da4));
    background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .panel-success > .panel-heading {
    background-image: -webkit-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
    background-image: -o-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#dff0d8), to(#d0e9c6));
    background-image: linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .panel-info > .panel-heading {
    background-image: -webkit-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
    background-image: -o-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d9edf7), to(#c4e3f3));
    background-image: linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .panel-warning > .panel-heading {
    background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
    background-image: -o-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fcf8e3), to(#faf2cc));
    background-image: linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .panel-danger > .panel-heading {
    background-image: -webkit-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
    background-image: -o-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f2dede), to(#ebcccc));
    background-image: linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
    background-repeat: repeat-x;
  }
  
  .well {
    background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
    background-image: -o-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e8e8e8), to(#f5f5f5));
    background-image: linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
    background-repeat: repeat-x;
    border-color: #dcdcdc;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  
  /*# sourceMappingURL=bootstrap-theme.css.map */
  
  /*----------------- ‰º‹L’Ç‰Á -----------------*/
  
  html {
    height: 100%;
    font-size: 62.5%;
    margin: 0;
  }
  
  body {
    font-weight: 500;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4;
    text-align: left;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
    word-break: break-all;
    -webkit-text-size-adjust: none;
  }
  
  ol, ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    color: #4d4d4d;
  
    &:link {
      text-decoration: none;
      color: #4d4d4d;
    }
  
    &:hover {
      text-decoration: none;
      color: #4d4d4d;
  
      img {
        filter: alpha(opacity = 80);
        -moz-opacity: 0.8;
        opacity: 0.8;
      }
    }
  
    &.link_txt {
      color: #3366FF;
      text-decoration: underline;
      font-size: 1.1em;
    }
  }
  
  /* img */
  
  .img-wrap {
    max-width: 100%;
  
    img {
      width: 100%;
      height: auto;
    }
  }
  
  /* flex */
  
  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  
    &.col2 .img-wrap + .inner_right {
      flex: 1;
      padding-left: 4%;
    }
  
    &.fl-row {
      flex-direction: row-reverse;
  
      &.col2 .img-wrap + .inner_left {
        flex: 1;
        padding-right: 4%;
      }
    }
  
    &.jc-end {
      justify-content: flex-end;
    }
  
    &.jc-cen {
      justify-content: center;
    }
  
    &.jc-bet {
      justify-content: space-between;
    }
  
    &.jc-ar {
      justify-content: space-around;
    }
  
    &.ai-cen {
      align-items: center;
    }
  }
  
  @media (max-width: 992px) {
    .flex {
      display: block;
    }
  
    .img-wrap {
      width: 100%;
      margin: 0 auto 1em;
    }
  
    .flex {
      &.col2 .img-wrap + .inner_right, &.fl-row.col2 .img-wrap + .inner_left {
        flex: none;
        padding: 0;
      }
    }
  }
  
  /*----- header -----*/
  
  #header {
    width: 100%;
    min-height: 855px;
    position: relative;
  }
  
  #pagetop {
    width: 100%;
    background-color: #24a6e3;
    height: 45px;
  
    .inner__pagetop {
      max-width: 1180px;
      margin: auto;
    }
  
    ul {
      float: right;
  
      li {
        display: inline-block;
  
        a {
          color: #fff;
          padding: 0 25px;
          line-height: 45px;
          display: block;
          font-size: 13px;
        }
  
        &:nth-child(1) a {
          background-color: #1d95e9;
        }
  
        &:nth-child(2) a {
          background-color: #1386d7;
        }
  
        &:nth-child(3) a {
          background-color: #1378d7;
        }
  
        &:nth-child(4) a {
          background-color: #0d6ec9;
        }
      }
    }
  }
  
  #header_inner {
    max-width: 1180px;
    margin: auto;
    padding: 15px 0;
    background-color: #fff;
  }
  
  .logo {
    float: left;
    max-width: 446px;
    margin: 5px 0 0 15px;
  
    img {
      width: 100%;
    }
  }
  
  #header_inner.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  
  /* h1 */
  
  #header h1 {
    max-width: 1000px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 350px;
    left: 0;
    right: 0;
  }
  
  /* header_contact */
  
  .header_contact {
    max-width: 1000px;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    margin: 0 auto;
    color: #000;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 120px;
    z-index: 2;
  
    .col-sm-4 {
      width: 31.333%;
      padding-left: 30px;
  
      &.center-col {
        border-left: 2px solid #ddd;
        border-right: 2px solid #ddd;
        padding: 0 1% 0 30px;
        margin: 0 1%;
        width: 33.333%;
      }
    }
  
    h2 {
      text-align: center;
      font-size: 26px;
      margin: 0 0 10px;
    }
  
    .col-sm-4 h3 {
      font-size: 16px;
      margin: 0;
      padding-left: 15px;
      background: url(../images/common/ico.png) no-repeat left center;
    }
  
    p {
      font-size: 30px;
      margin: 0;
  
      em {
        font-style: normal;
        font-size: 0.85em;
      }
  
      &.small {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
  }
  
  @media (min-width: 1080px) {
    .logo {
      width: 40%;
    }
  }
  
  @media (min-width: 960px) {
    #header_inner {
      min-height: 80px;
      padding: 10px 5px;
    }
  
    .logo {
      width: 40%;
    }
  }
  
  @media (max-width: 960px) {
    #header_inner {
      padding: 5px 5px;
      height: 65px;
    }
  
    .header_contact p {
      font-size: 24px;
      margin: 0;
    }
  
    .logo img {
      width: auto;
      height: 40px;
    }
  }
  
  @media (max-width: 768px) {
    #header {
      min-height: 520px;
      border-top: 4px solid #24a6e3;
    }
  
    #pagetop {
      display: none;
    }
  
    #header_inner {
      padding: 13px 5px;
      height: 55px;
    }
  
    .logo {
      width: auto;
      height: 35px;
      margin: 0;
  
      img {
        width: auto;
        height: 35px;
      }
    }
  
    #header {
      h1 {
        top: 40%;
      }
  
      section.sp--only h1 {
        width: 94%;
        top: inherit;
        left: 0;
        right: 0;
        bottom: 20%;
        margin: auto;
        color: #fff;
        font-size: 3.2rem;
        line-height: 1.3;
        text-align: left;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  
        span.small {
          color: #fff;
          margin-top: 0;
          line-height: 1.4;
          font-size: 1.3rem;
          font-weight: 400;
  
          em {
            font-size: 1.8rem;
            font-style: normal;
          }
        }
      }
    }
  
    .header_contact {
      display: none;
    }
  }
  
  /* top--key_img */
  
  .top--key_img {
    position: absolute;
    width: 100%;
    top: 45px;
    left: 0;
    text-align: center;
  
    div {
      &.key01 {
        background: url( ../images/top/key_img01_bg.jpg) no-repeat center center;
      }
  
      &.key02 {
        background: url( ../images/top/key_img02_bg.jpg) no-repeat center center;
      }
  
      &.key03 {
        background: url( ../images/top/key_img03_bg.jpg) no-repeat center center;
      }
    }
  }
  
  .slick-slide figure {
    margin: auto;
    max-width: 1000px;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .top--key_img {
      top: 60px;
  
      li img {
        display: none;
      }
    }
  }
  
  /* sub--key_img */
  
  .sub--key_img {
    position: absolute;
    width: 100%;
    height: 330px;
    top: 28px;
    left: 0;
    z-index: -1;
    text-align: center;
    overflow: hidden;
  
    li {
      background: url(../images/common/key_img_bg.jpg) no-repeat center center;
      height: 305px;
      background-size: cover;
    }
  }
  
  #header.sub--header {
    min-height: 340px;
  
    h1 {
      top: 210px;
  
      span {
        &.mincho {
          font-size: 44px;
          line-height: 140%;
          color: #fff;
          text-shadow: 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 10px #000;
        }
  
        &.mincho_sm, &.mincho_sm02 {
          font-size: 35px;
          line-height: 140%;
          margin-top: -20px;
          display: inline-block;
        }
      }
    }
  }
  
  @media (max-width: 999px) {
    .sub--key_img li img {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    #header.sub--header {
      min-height: 230px;
    }
  
    .sub--key_img {
      height: 180px;
      top: 50px;
  
      li {
        background: url(../images/common/key_img_bg.jpg) no-repeat center center;
        background-size: cover;
        height: 180px;
      }
    }
  
    #header.sub--header h1 {
      top: 125px;
  
      span {
        &.mincho {
          font-size: 28px;
        }
  
        &.mincho_sm {
          font-size: 24px;
          margin-top: -15px;
        }
  
        &.mincho_sm02 {
          font-size: 22px;
          margin-top: -15px;
        }
      }
    }
  }
  
  /*----- Bread_crumb_List -----*/
  
  .bread {
    max-width: 1000px;
    width: 100%;
    margin: 15px auto 4em;
  }
  
  .bread_crumb {
    width: 100%;
    margin: auto;
    text-align: left;
  
    li {
      display: inline;
  
      &:after {
        content: '>';
        padding: 0 10px;
        color: #24a6e3;
      }
  
      &:last-child:after {
        content: '';
      }
  
      a {
        color: #24a6e3;
      }
    }
  }
  
  @media (max-width: 768px) {
    .bread {
      display: none;
    }
  }
  
  /*----- pagetop -----*/
  
  .page--top {
    max-width: 1180px;
    margin: 0 auto;
    height: 60px;
  
    a {
      float: right;
      display: block;
      width: 60px;
      height: 60px;
    }
  
    img {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .page--top {
      height: 40px;
  
      a {
        width: 40px;
        height: 40px;
      }
    }
  }
  
  /*----- footer -----*/
  
  #footer {
    width: 100%;
    margin: auto;
  
    #footer_inner {
      width: 100%;
      padding: 30px 0 20px;
      background-color: #333;
  
      a {
        color: #fff;
      }
  
      p {
        margin: 0 0 5px;
      }
  
      .col-sm-2 ul li, .col-sm-3 ul li {
        margin-bottom: 20px;
      }
  
      .col-sm-7 ul {
        margin-bottom: 20px;
  
        li {
          display: inline-block;
          width: 31.333%;
          padding-left: 6px;
          margin: 5px 1.5% 0 0;
          vertical-align: top;
          position: relative;
  
          a {
            font-size: 0.85em;
            color: #aaa;
          }
  
          &:before {
            content: "";
            background: url(../images/common/indent.png) no-repeat;
            position: absolute;
            top: 8px;
            left: 0;
            width: 4px;
            height: 4px;
            background-size: 4px 4px;
          }
        }
      }
    }
  }
  
  .office_overview {
    padding: 20px 0 15px;
    background-color: #f7f7f7;
  
    dl {
      margin-bottom: 0;
  
      dt {
        margin-right: 20px;
      }
  
      dd {
        flex: 1;
  
        ul li {
          font-size: .95em;
          margin-bottom: .5em;
        }
      }
    }
  }
  
  .footer--office {
    padding: 2em 0 1.5em;
    margin-bottom: 2em;
    border-bottom: 1px solid #ddd;
  
    ul {
      overflow: hidden;
  
      li {
        width: 31.333%;
        float: left;
        margin-right: 3%;
        position: relative;
  
        &:last-child {
          margin-right: 0;
        }
  
        div a.pos_btn {
          display: inline-block;
          padding: 2px 15px;
          background-color: #ee9223;
          color: #fff;
          font-size: 11px;
          border-radius: 10px;
          position: absolute;
          top: 0;
          right: 5%;
        }
  
        p {
          margin: 0;
          font-size: 12px;
        }
      }
    }
  
    h3 {
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 10px;
    }
  }
  
  #footer .row .pull-right ul {
    text-align: right;
  
    li {
      display: inline;
      padding: 0 10px;
    }
  }
  
  @media (max-width: 1199px) {
    .inner__block--big .row {
      width: 96%;
      margin: auto;
    }
  }
  
  @media (max-width: 768px) {
    #footer {
      background-color: #f8f8f8;
  
      #footer_inner {
        display: none;
      }
    }
  
    .office_overview {
      width: 96%;
      padding: 30px 0 0;
      margin: auto;
  
      dt {
        margin-bottom: .5em;
      }
    }
  
    .footer--office {
      padding: 1em 0 0;
      margin-bottom: 1em;
  
      ul li {
        width: 96%;
        float: none;
        margin: 3% auto;
      }
  
      h3 {
        margin-bottom: 5px;
      }
  
      ul li {
        &:last-child {
          margin-right: auto;
        }
  
        div a.pos_btn {
          right: 60%;
        }
      }
  
      a.telhref {
        font-size: 1.25em;
        font-weight: 500;
      }
    }
  
    #footer .row {
      width: 96%;
      margin: auto;
  
      .pull-left {
        float: none !important;
        width: 70%;
        margin: 0 0 15px;
  
        img {
          width: 100%;
        }
      }
  
      .pull-right {
        float: none !important;
        text-align: left;
        font-size: 10px;
  
        ul {
          display: none;
        }
      }
    }
  
    /* footerFloatingMenu */
  
    #footerFloatingMenu {
      display: block;
      width: 100%;
      padding: 2% 3% 3%;
      background: #fff;
      position: fixed;
      left: 0px;
      bottom: 0px;
      z-index: 2;
  
      li {
        float: left;
        margin: 0 0 2%;
        width: 33.333%;
        font-size: 0.9em;
  
        img {
          width: 100%;
        }
      }
    }
  
    .fixed_txt {
      clear: both;
      margin-bottom: 5px;
      text-align: center;
      font-size: 0.85em;
    }
  
    .mb_area {
      background-color: #f8f8f8;
      height: 140px;
      display: block;
    }
  
    .tel-link--btn li {
      background-color: #24a6e3;
      border: 1px solid #fff;
      color: #111;
      width: 100%;
      text-align: center;
      padding: 4% 0 3%;
  
      span {
        display: block;
        text-align: center;
        line-height: 1.4;
      }
  
      a {
        color: #fff !important;
        text-decoration: none;
      }
    }
  
    .spbtn {
      width: 100%;
      margin: auto;
      background-color: #333;
      text-align: center;
  
      a {
        display: block;
        background: none;
        border: none;
        color: #fff !important;
        padding: 5px;
        font-size: 0.85em;
      }

      &+&{
        margin-top: 5px;
      }

      //btn-line
      &.btn-line{
        background-color: #01B901;
      }
    }
  
    @media only screen and (orientation: landscape) {
      #footerFloatingMenu {
        width: 96%;
        padding: 1% 2% 1.5%;
  
        .fixed_txt {
          text-align: center;
        }
  
        p {
          a {
            text-align: center;
          }
  
          img {
            width: 80%;
          }
        }
      }
  
      .mb_area {
        height: 150px;
      }
    }
  }
  
  @media (max-width: 480px) {
    .footer--office ul li div a.pos_btn {
      right: 20%;
    }
  }
  
  /* ƒAƒjƒ[ƒVƒ‡ƒ“
  ----------------------------------- */
  
  .animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1s;
    animation-delay: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .slow {
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .slow01 {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .animated {
    &.infinite {
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
  
    &.hinge {
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-10%, 0, 0);
      transform: translate3d(-10%, 0, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-10%, 0, 0);
      transform: translate3d(-10%, 0, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }
  
  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(5%, 0, 0);
      transform: translate3d(5%, 0, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(5%, 0, 0);
      transform: translate3d(5%, 0, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -30%, 0);
      transform: translate3d(0, -30%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -30%, 0);
      transform: translate3d(0, -30%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
  
  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
  
  /*----- figure effect -----*/
  
  figure.effect01 {
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: pointer;
  
    figcaption {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-transition: all .4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
  }
  
  .top-salon-area figure.effect01 {
    &.slon-left figcaption {
      background-color: rgba(34, 24, 8, 0.7);
    }
  
    &.slon-right figcaption {
      background-color: rgba(23, 23, 23, 0.7);
    }
  }
  
  figure.effect01 {
    img.scale-img {
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      -webkit-transition: all .4s linear;
      transition: all .4s linear;
      z-index: 0;
    }
  
    &:hover {
      img.scale-img {
        -ms-transform: scale(1.15);
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
      }
  
      figcaption {
        background-color: rgba(13, 38, 77, 0.9);
      }
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1199px) {
    /*----- figure effect -----*/
  
    figure.effect01 figcaption div {
      padding: 5%;
  
      .sn {
        display: none;
      }
    }
  }
  
  /*----- common -----*/
  
  .inner__block--big {
    max-width: 1100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .inner__block {
    max-width: 1000px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (min-width: 769px) {
    .sp--only {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .inner__block--big {
      width: 100%;
    }
  
    .inner__block {
      width: 94%;
    }
  }
  
  .mt0 {
    margin-top: 0 !important;
  }
  
  .mt1 {
    margin-top: 1em;
  }
  
  .mt2 {
    margin-top: 2em;
  }
  
  .mt3 {
    margin-top: 3em;
  }
  
  .mt4 {
    margin-top: 4em;
  }
  
  .mt5 {
    margin-top: 5em;
  }
  
  .mb0 {
    margin-bottom: 0 !important;
  }
  
  .mb1 {
    margin-bottom: 1em;
  }
  
  .mb2 {
    margin-bottom: 2em;
  }
  
  .mb3 {
    margin-bottom: 3em;
  }
  
  .mb4 {
    margin-bottom: 4em;
  }
  
  .mb5 {
    margin-bottom: 5em;
  }
  
  .pt0 {
    padding-top: 0 !important;
  }
  
  .pt1 {
    padding-top: 1em;
  }
  
  .pt2 {
    padding-top: 2em;
  }
  
  .pt3 {
    padding-top: 3em;
  }
  
  .pt4 {
    padding-top: 4em;
  }
  
  .pt5 {
    padding-top: 5em;
  }
  
  .pb0 {
    padding-bottom: 0 !important;
  }
  
  .pb1 {
    padding-bottom: 1em;
  }
  
  .pb2 {
    padding-bottom: 2em;
  }
  
  .pb3 {
    padding-bottom: 3em;
  }
  
  .pb4 {
    padding-bottom: 4em;
  }
  
  .pb5 {
    padding-bottom: 5em;
  }
  
  /* btn-style */
  
  a.sm_btn {
    display: inline-block;
    padding: 4px 15px 2px;
    color: #ee9223;
    border: 1px solid #ee9223;
    border-radius: 15px;
    font-size: 11px;
    box-sizing: border-box;
    -webkit-transition: all .5s;
    transition: all .5s;
  
    &:hover {
      background-color: #ee9223;
      color: #fff;
    }
  }
  
  /*=============================
      wp-pagenavi
  ===============================*/
  
  .wp-pagenavi {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    display: block;
    clear: both;
    padding: 20px 10px 20px 10px;
  
    a {
      padding: 10px 15px !important;
      margin: 3px !important;
      text-decoration: none !important;
      background: #fff !important;
      color: #999 !important;
  
      &:link, &:visited, &:active {
        padding: 10px 15px !important;
        margin: 3px !important;
        text-decoration: none !important;
        background: #fff !important;
        color: #999 !important;
      }
  
      &:hover {
        color: #444 !important;
      }
    }
  
    span {
      &.pages {
        padding: 10px 15px !important;
        margin: 3px !important;
        color: #999 !important;
      }
  
      &.current {
        padding: 10px 15px !important;
        margin: 3px !important;
        text-decoration: none !important;
        background: #222 !important;
        font-weight: bold !important;
        color: #fff !important;
      }
  
      &.extend {
        padding: 10px 15px !important;
        margin: 3px !important;
        text-decoration: none !important;
        background: #fff !important;
      }
    }
  
    a.nextpostslink {
      display: inline;
      background: url(../images/common/arw_01.png) no-repeat right center !important;
      width: 24px;
      height: 9px;
    }
  }